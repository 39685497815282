import React, {useState} from 'react';
import {Button, Label, Message} from 'semantic-ui-react';
import {Form} from 'formsy-semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import useFormSubmission from '../../hooks/use-form-submission';
import logger from '../../logger';
import SEO from '../../components/shared/SEO/SEO';
import BotCheck from '../../components/site/elements/BotCheck';
import Page from '../../components/site/layouts/Page';
import useSiteSettings from '../../hooks/use-site-settings';
import PageTitle from '../../components/site/PageTitle';
import PageSubtitle from '../../components/site/PageSubtitle';

const Contact = () => {
  const siteSettings = useSiteSettings();
  const [botcheckResponse, setBotcheckResponse] = useState(null);
  const {
    submitForm,
    formIsSubmitting,
    formSuccess,
    formErrors,
  } = useFormSubmission({campaign: 'General contact'});
  const [message, setMessage] = useState(null);

  const validated = !!botcheckResponse;
  const isPosted = !!formSuccess;

  const onCaptchaLoaded = () => {
    logger.debug('Bot check engaged');
  };
  const onCaptchaValidated = setBotcheckResponse;
  const onCaptchaExpired = () => setBotcheckResponse(null);
  const onValidSubmit = formData => {
    // logger.debug(JSON.stringify(formData, null, 2));
    const submission = {
      messageType: 'contact_home',
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      botcheckResponse,
      details: {message},
    };

    return submitForm(submission);
  };

  return (
    <Page>
      <SEO
        title="Find a Realtor In North, Central, South Austin"
        description="Looking for a realtor in Austin? My goal is to educate and assist with any real estate transaction"
        pathname="contact/"
        keywords={[
          'Contact a Realtor',
          'Angie Ufomata Realtor',
          'North Austin',
          'South Austin',
          'Central Austin',
        ]}
      />

      <PageTitle as="h1">
        Contact
        <PageSubtitle>I would love to hear from you!</PageSubtitle>
      </PageTitle>

      <Form
        onValidSubmit={onValidSubmit}
        loading={formIsSubmitting}
        error={!!formErrors}>
        <Form.Input
          required
          name="name"
          label="Name"
          placeholder="Name"
          validations="isWords"
          errorLabel={<Label color="red" pointing />}
          validationErrors={{
            isWords: 'No numbers or special characters allowed',
            isDefaultRequiredValue: 'Name is required',
          }}
        />

        <Form.Group widths="equal">
          <Form.Input
            required
            name="email"
            label="Email"
            placeholder="Email"
            validations="isEmail"
            errorLabel={<Label color="red" pointing />}
            validationErrors={{
              isEmail: 'Please enter a valid email',
              isDefaultRequiredValue: 'Email is required',
            }}
          />
          <Form.Input
            required
            name="phone"
            label="Phone"
            placeholder="Phone"
            validations={{
              matchRegexp: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/,
            }}
            errorLabel={<Label color="red" pointing />}
            validationErrors={{
              matchRegexp: 'Please enter a valid phone number',
              isDefaultRequiredValue: 'Phone is required',
            }}
          />
        </Form.Group>

        <Form.Field
          control={TextareaAutosize}
          minRows={3}
          name="message"
          placeholder="Tell me more"
          onChange={ev => setMessage(ev.target.value)}
        />

        <BotCheck
          onLoad={onCaptchaLoaded}
          onValidated={onCaptchaValidated}
          onExpired={onCaptchaExpired}
        />

        <Button
          primary
          fluid
          className="large-tm"
          type="submit"
          disabled={!botcheckResponse || (validated && isPosted)}>
          Send
        </Button>

        {formErrors && (
          <Message
            error
            header="There were some errors with your submission"
            list={formErrors}
          />
        )}
      </Form>

      {isPosted && (
        <Message
          success
          header={`Got your message ${formSuccess.name.split(' ')[0]}`}
          content={`I will be in contact soon. Feel free to reach me at ${siteSettings.phone}. Text messages work great. Chat soon.`}
        />
      )}
    </Page>
  );
};

export default Contact;
